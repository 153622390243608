import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import * as Cookies from 'js-cookie';
import { getUserDetails } from '../apis/accountApi';
import mixpanelAnalytics from '../utils/analytics/providers/mixpanelAnalytics';
import { getLoginUrl } from '../apis/loginApi';
import { useLocation } from 'react-router-dom';
import { setSessionUrl } from '../redux/actions';

// Define the shape of your context value
interface LoginContextValue {
    isLoggedIn: boolean;
    isLoading: boolean;
    accessToken?: string;
    login: (email: string, accessToken: string, expirySeconds: number) => void;
    logout: () => void;
    redirectToLogin: () => void;
    email: string;
    isTrade: boolean;
}

export interface LoginProviderProps {
    children: ReactNode;
}

const initialContextValue: LoginContextValue = {
    isLoggedIn: false,
    isLoading: false,
    email: '',
    accessToken: undefined,
    isTrade: false,
    login: () => {},
    logout: () => {},
    redirectToLogin: () => {},
};

export const LoginContext =
    createContext<LoginContextValue>(initialContextValue);

export function useLogin() {
    const context = useContext(LoginContext);
    if (!context) {
        throw new Error('No Login Context initialised.');
    }
    return context;
}

export function LoginProvider({ children }: LoginProviderProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [accessToken, setAccessToken] = useState<string | undefined>(
        Cookies.get('access-token')
    );
    const [isTrade, setIsTrade] = useState<boolean>(false);
    const location = useLocation();

    const mixpanel = useMemo(() => new mixpanelAnalytics(), []);
    const getLoggedInUserDetails = useCallback(
        (accessToken: string) => {
            getUserDetails(accessToken)
                .then(response => {
                    if (response.success && response.result !== null) {
                        setIsLoggedIn(true);
                        setEmail(response.result.email);
                        mixpanel.loginUser(response.result.email);
                        setIsTrade(response.result.isTrade);
                    }
                })
                .catch(() => {
                    setIsLoggedIn(false);
                    setEmail('');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [mixpanel]
    );

    useEffect(() => {
        setIsLoading(true);
        if (email === '' && accessToken !== undefined) {
            getLoggedInUserDetails(accessToken);
        } else if (accessToken === undefined) {
            setIsLoggedIn(false);
            setEmail('');
            setIsLoading(false);
        }
    }, [email, mixpanel, accessToken, getLoggedInUserDetails]);

    useEffect(() => {
        if (!location.pathname.includes('login')) {
            setSessionUrl(location.pathname + location.search);
        }
    }, [location.pathname, location.search]);

    const login = (
        email: string,
        accessToken: string,
        expirySeconds: number
    ): void => {
        var expiryTime = new Date(new Date().getTime() + expirySeconds);
        Cookies.set('access-token', accessToken, {
            expires: expiryTime,
            secure: true,
        });

        window.setTimeout(redirectToLogin, expirySeconds);
        setAccessToken(accessToken);
        setIsLoggedIn(true);
        mixpanel.loginUser(email);
        setEmail(email);
        getLoggedInUserDetails(accessToken);
    };

    const logout = (): void => {
        setIsLoggedIn(false);
        setEmail('');
        Cookies.remove('access-token');
        setAccessToken(undefined);
        mixpanel.logoutUser();
        setIsTrade(false);
    };

    return (
        <LoginContext.Provider
            value={{
                isLoggedIn,
                isLoading,
                email,
                login,
                logout,
                accessToken,
                redirectToLogin,
                isTrade,
            }}
        >
            {children}
        </LoginContext.Provider>
    );

    function redirectToLogin() {
        window.location.replace(getLoginUrl());
    }
}
