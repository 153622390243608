import { ReactComponent as accommodationlIcon } from '../../../assets/images/icons/accommodation.svg';
import { ReactComponent as airplaneLandingIcon } from '../../../assets/images/icons/airplane-landing.svg';
import { ReactComponent as airplaneIcon } from '../../../assets/images/icons/airplane.svg';
import { ReactComponent as airplaneTakeoffIcon } from '../../../assets/images/icons/airplane-takeoff.svg';
import { ReactComponent as arrowRightIcon } from '../../../assets/images/icons/arrow-right.svg';
import { ReactComponent as arrowRightLongIcon } from '../../../assets/images/icons/arrow-right-long.svg';
import { ReactComponent as atolIcon } from '../../../assets/images/icons/atol-logo.svg';
import { ReactComponent as badgeIcon } from '../../../assets/images/icons/badge.svg';
import { ReactComponent as barIcon } from '../../../assets/images/icons/bar-thin.svg';
import { ReactComponent as bedIcon } from '../../../assets/images/icons/bed.svg';
import { ReactComponent as bedLightIcon } from '../../../assets/images/icons/bed-light.svg';
import { ReactComponent as builtForYouIcon } from '../../../assets/images/icons/built-for-you.svg';
import { ReactComponent as bookAtlas } from '../../../assets/images/icons/book-atlas.svg';
import { ReactComponent as bookOpen } from '../../../assets/images/icons/book-open-cover-light.svg';
import { ReactComponent as boxOpenThinIcon } from '../../../assets/images/icons/box-open-thin.svg';
import { ReactComponent as briefcaseThinIcon } from '../../../assets/images/icons/briefcase-thin.svg';
import { ReactComponent as calendarIcon } from '../../../assets/images/icons/calendar-18.svg';
import { ReactComponent as calendarRangeIcon } from '../../../assets/images/icons/calendar-range-thin.svg';
import { ReactComponent as callbackIcon } from '../../../assets/images/icons/callback.svg';
import { ReactComponent as cameraIcon } from '../../../assets/images/icons/camera.svg';
import { ReactComponent as checkeredFlagIcon } from '../../../assets/images/icons/checkeredFlag.svg';
import { ReactComponent as checkThinIcon } from '../../../assets/images/icons/check-thin.svg';
import { ReactComponent as checkIcon } from '../../../assets/images/icons/check.svg';
import { ReactComponent as chevronIcon } from '../../../assets/images/icons/chevron.svg';
import { ReactComponent as chevronCircleIcon } from '../../../assets/images/icons/chevron-circle.svg';
import { ReactComponent as circlePlusIcon } from '../../../assets/images/icons/circleplus.svg';
import { ReactComponent as cirlceMinusIcon } from '../../../assets/images/icons/circleminus.svg';
import { ReactComponent as circlemMinusSolidIcon } from '../../../assets/images/icons/circleMinusSolid.svg';
import { ReactComponent as cityIcon } from '../../../assets/images/icons/city.svg';
import { ReactComponent as closeIcon } from '../../../assets/images/icons/close.svg';
import { ReactComponent as coinsIcon } from '../../../assets/images/icons/coins.svg';
import { ReactComponent as compassIcon } from '../../../assets/images/icons/compass.svg';
import { ReactComponent as compassThinIcon } from '../../../assets/images/icons/compass-thin.svg';
import { ReactComponent as contactIcon } from '../../../assets/images/icons/contact.svg';
import { ReactComponent as crossIcon } from '../../../assets/images/icons/cross.svg';
import { ReactComponent as disabledThinIcon } from '../../../assets/images/icons/wheelchair-thin.svg';
import { ReactComponent as downloadIcon } from '../../../assets/images/icons/download.svg';
import { ReactComponent as earthIcon } from '../../../assets/images/icons/earth-americas-solid.svg';
import { ReactComponent as editIcon } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as emptyHeartIcon } from '../../../assets/images/icons/emptyHeart.svg';
import { ReactComponent as entertainmentIcon } from '../../../assets/images/icons/tv-music-thin.svg';
import { ReactComponent as familyIcon } from '../../../assets/images/icons/family.svg';
import { ReactComponent as filterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as financialProtectionIcon } from '../../../assets/images/icons/financial-protection.svg';
import { ReactComponent as fitnessIcon } from '../../../assets/images/icons/dumbbell-thin.svg';
import { ReactComponent as flagIcon } from '../../../assets/images/icons/flag.svg';
import { ReactComponent as flexibleIcon } from '../../../assets/images/icons/flexible.svg';
import { ReactComponent as flightIcon } from '../../../assets/images/icons/flight.svg';
import { ReactComponent as freeDateChangesIcon } from '../../../assets/images/icons/free-date-changes.svg';
import { ReactComponent as fullHeartIcon } from '../../../assets/images/icons/fullHeart.svg';
import { ReactComponent as groupIcon } from '../../../assets/images/icons/group.svg';
import { ReactComponent as headsetIcon } from '../../../assets/images/icons/headset.svg';
import { ReactComponent as heartIcon } from '../../../assets/images/icons/heart.svg';
import { ReactComponent as heartLightIcon } from '../../../assets/images/icons/heart-light.svg';
import { ReactComponent as hikingIcon } from '../../../assets/images/icons/hiking.svg';
import { ReactComponent as hikingLightIcon } from '../../../assets/images/icons/hiking-light.svg';
import { ReactComponent as holdSpaceLightIcon } from '../../../assets/images/icons/hand-holding-heart-light.svg';
import { ReactComponent as hourglassIcon } from '../../../assets/images/icons/hourglass.svg';
import { ReactComponent as houseRegIcon } from '../../../assets/images/icons/house-regular.svg';
import { ReactComponent as hotelLightIcon } from '../../../assets/images/icons/hotel.svg';
import { ReactComponent as hotelThinIcon } from '../../../assets/images/icons/hotel-thin.svg';
import { ReactComponent as infoIcon } from '../../../assets/images/icons/info.svg';
import { ReactComponent as internetIcon } from '../../../assets/images/icons/globe-thin.svg';
import { ReactComponent as elevatorThinIcon } from '../../../assets/images/icons/elevator-thin.svg';
import { ReactComponent as lockThinIcon } from '../../../assets/images/icons/lock-keyhole-thin.svg';
import { ReactComponent as mailIcon } from '../../../assets/images/icons/envelope.svg';
import { ReactComponent as mealsIcon } from '../../../assets/images/icons/meals.svg';
import { ReactComponent as mealsThinIcon } from '../../../assets/images/icons/meals-thin.svg';
import { ReactComponent as mapIcon } from '../../../assets/images/icons/map.svg';
import { ReactComponent as mapLocationIcon } from '../../../assets/images/icons/map-location.svg';
import { ReactComponent as mapMarkerIcon } from '../../../assets/images/icons/map-marker.svg';
import { ReactComponent as mapPinIcon } from '../../../assets/images/icons/mapPin.svg';
import { ReactComponent as menuIcon } from '../../../assets/images/icons/menu.svg';
import { ReactComponent as minusIcon } from '../../../assets/images/icons/minus.svg';
import { ReactComponent as moneyWaveIcon } from '../../../assets/images/icons/money-wave.svg';
import { ReactComponent as mountainIcon } from '../../../assets/images/icons/mountain.svg';
import { ReactComponent as noSmokingIcon } from '../../../assets/images/icons/ban-smoking-thin.svg';
import { ReactComponent as padlockIcon } from '../../../assets/images/icons/padlock.svg';
import { ReactComponent as paperPlaneIcon } from '../../../assets/images/icons/paper-plane.svg';
import { ReactComponent as peopleIcon } from '../../../assets/images/icons/people-simple-light.svg';
import { ReactComponent as personIcon } from '../../../assets/images/icons/person-light.svg';
import { ReactComponent as personHikingIcon } from '../../../assets/images/icons/person-hiking.svg';
import { ReactComponent as parkingThinIcon } from '../../../assets/images/icons/parking-thin.svg';
import { ReactComponent as phoneIcon } from '../../../assets/images/icons/phone.svg';
import { ReactComponent as phoneSolidIcon } from '../../../assets/images/icons/phone-solid.svg';
import { ReactComponent as plusIcon } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as questionIcon } from '../../../assets/images/icons/question.svg';
import { ReactComponent as refundIcon } from '../../../assets/images/icons/refund.svg';
import { ReactComponent as restaurantThinIcon } from '../../../assets/images/icons/restaurant-thin.svg';
import { ReactComponent as rightLeftArrowIcon } from '../../../assets/images/icons/right-left-arrow.svg';
import { ReactComponent as searchIcon } from '../../../assets/images/icons/search.svg';
import { ReactComponent as servicesExtrasThinIcon } from '../../../assets/images/icons/bell-concierge-thin.svg';
import { ReactComponent as shieldIcon } from '../../../assets/images/icons/shield.svg';
import { ReactComponent as shieldCardIcon } from '../../../assets/images/icons/shield-card.svg';
import { ReactComponent as shieldCoinsIcon } from '../../../assets/images/icons/shield-coins.svg';
import { ReactComponent as shieldTickIcon } from '../../../assets/images/icons/shield-tick.svg';
import { ReactComponent as slidersIcon } from '../../../assets/images/icons/sliders.svg';
import { ReactComponent as spaIcon } from '../../../assets/images/icons/spa-thin.svg';
import { ReactComponent as speechBubbleIcon } from '../../../assets/images/icons/speech-bubble.svg';
import { ReactComponent as starIcon } from '../../../assets/images/icons/star.svg';
import { ReactComponent as starThinIcon } from '../../../assets/images/icons/star-thin.svg';
import { ReactComponent as starRatingIcon } from '../../../assets/images/icons/starrating.svg';
import { ReactComponent as suitaceIcon } from '../../../assets/images/icons/suitcase-thin.svg';
import { ReactComponent as swimmingIcon } from '../../../assets/images/icons/person-swimming-thin.svg';
import { ReactComponent as squareInfoIcon } from '../../../assets/images/icons/square-info-thin.svg';
import { ReactComponent as teamIcon } from '../../../assets/images/icons/team.svg';
import { ReactComponent as tickIcon } from '../../../assets/images/icons/tick.svg';
import { ReactComponent as timerIcon } from '../../../assets/images/icons/timer.svg';
import { ReactComponent as timesIcon } from '../../../assets/images/icons/times.svg';
import { ReactComponent as transportIcon } from '../../../assets/images/icons/van-shuttle.svg';
import { ReactComponent as transportThinIcon } from '../../../assets/images/icons/van-shuttle-thin.svg';
import { ReactComponent as tripAdvisorLogoIcon } from '../../../assets/images/icons/tripadvisor/tripadvisorLogo.svg';
import { ReactComponent as tripAdvisorIcon0 } from '../../../assets/images/icons/tripadvisor/bubblerating0.svg';
import { ReactComponent as tripAdvisorIcon1 } from '../../../assets/images/icons/tripadvisor/bubblerating1.svg';
import { ReactComponent as tripAdvisorIcon2 } from '../../../assets/images/icons/tripadvisor/bubblerating2.svg';
import { ReactComponent as tripAdvisorIcon3 } from '../../../assets/images/icons/tripadvisor/bubblerating3.svg';
import { ReactComponent as tripAdvisorIcon4 } from '../../../assets/images/icons/tripadvisor/bubblerating4.svg';
import { ReactComponent as tripAdvisorIcon5 } from '../../../assets/images/icons/tripadvisor/bubblerating5.svg';
import { ReactComponent as tripAdvisorIcon6 } from '../../../assets/images/icons/tripadvisor/bubblerating6.svg';
import { ReactComponent as tripAdvisorIcon7 } from '../../../assets/images/icons/tripadvisor/bubblerating7.svg';
import { ReactComponent as tripAdvisorIcon8 } from '../../../assets/images/icons/tripadvisor/bubblerating8.svg';
import { ReactComponent as tripAdvisorIcon9 } from '../../../assets/images/icons/tripadvisor/bubblerating9.svg';
import { ReactComponent as tripAdvisorIcon10 } from '../../../assets/images/icons/tripadvisor/bubblerating10.svg';
import { ReactComponent as userIcon } from '../../../assets/images/icons/circle-user-light.svg';
import { ReactComponent as userSolidIcon } from '../../../assets/images/icons/user-solid.svg';
import { ReactComponent as veganFriendlyIcon } from '../../../assets/images/icons/Vegan_friendly_icon-borderless.svg';
import { ReactComponent as wifiIcon } from '../../../assets/images/icons/wifi.svg';
import { ReactComponent as xIcon } from '../../../assets/images/icons/x.svg';

const iconFileMapper = {
    accommodation: accommodationlIcon,
    airplaneLanding: airplaneLandingIcon,
    airplane: airplaneIcon,
    airplaneTakeoff: airplaneTakeoffIcon,
    arrowRight: arrowRightIcon,
    arrowRightLong: arrowRightLongIcon,
    atol: atolIcon,
    badge: badgeIcon,
    bar: barIcon,
    bed: bedIcon,
    bedLight: bedLightIcon,
    buildingcharacteristics: hotelThinIcon,
    builtForYou: builtForYouIcon,
    businessfacilities: briefcaseThinIcon,
    bookAtlas: bookAtlas,
    book: bookOpen,
    calendar: calendarIcon,
    calendarRange: calendarRangeIcon,
    callback: callbackIcon,
    camera: cameraIcon,
    check: checkIcon,
    checkThin: checkThinIcon,
    checkeredFlag: checkeredFlagIcon,
    chevron: chevronIcon,
    chevronCircle: chevronCircleIcon,
    circleMinus: cirlceMinusIcon,
    circlemMinusSolid: circlemMinusSolidIcon,
    circlePlus: circlePlusIcon,
    city: cityIcon,
    close: closeIcon,
    coins: coinsIcon,
    compass: compassIcon,
    compassThin: compassThinIcon,
    contact: contactIcon,
    cross: crossIcon,
    disabledThin: disabledThinIcon,
    download: downloadIcon,
    earth: earthIcon,
    edit: editIcon,
    elevator: elevatorThinIcon,
    entertainmentandfamilyservices: entertainmentIcon,
    emptyHeart: emptyHeartIcon,
    family: familyIcon,
    filter: filterIcon,
    financialProtection: financialProtectionIcon,
    fitness: fitnessIcon,
    flag: flagIcon,
    flexible: flexibleIcon,
    flight: flightIcon,
    fullHeart: fullHeartIcon,
    fooddrink: mealsThinIcon,
    freeDateChanges: freeDateChangesIcon,
    general: squareInfoIcon,
    globe: internetIcon,
    group: groupIcon,
    headset: headsetIcon,
    heart: heartIcon,
    heartLight: heartLightIcon,
    hiking: hikingIcon,
    hikingLight: hikingLightIcon,
    holdSpaceLight: holdSpaceLightIcon,
    hotel: hotelLightIcon,
    hourglass: hourglassIcon,
    houseIcon: houseRegIcon,
    info: infoIcon,
    internet: internetIcon,
    lockThin: lockThinIcon,
    mail: mailIcon,
    map: mapIcon,
    mapLocation: mapLocationIcon,
    mapMarker: mapMarkerIcon,
    mapPin: mapPinIcon,
    meals: mealsIcon,
    menu: menuIcon,
    minus: minusIcon,
    moneyWave: moneyWaveIcon,
    mountain: mountainIcon,
    noSmoking: noSmokingIcon,
    padlock: padlockIcon,
    paperPlane: paperPlaneIcon,
    parking: parkingThinIcon,
    people: peopleIcon,
    person: personIcon,
    personHiking: personHikingIcon,
    phone: phoneIcon,
    phoneSolid: phoneSolidIcon,
    plus: plusIcon,
    question: questionIcon,
    refund: refundIcon,
    restaurant: restaurantThinIcon,
    rightLeftArrow: rightLeftArrowIcon,
    roomamenities: boxOpenThinIcon,
    roomtypes: bedIcon,
    safetysecurity: lockThinIcon,
    search: searchIcon,
    servicesextras: servicesExtrasThinIcon,
    shield: shieldIcon,
    shieldCard: shieldCardIcon,
    shieldCoins: shieldCoinsIcon,
    shieldTick: shieldTickIcon,
    sliders: slidersIcon,
    spa: spaIcon,
    speechBubble: speechBubbleIcon,
    starRating: starRatingIcon,
    starThin: starThinIcon,
    star: starIcon,
    suitcase: suitaceIcon,
    swimming: swimmingIcon,
    team: teamIcon,
    tick: tickIcon,
    timer: timerIcon,
    times: timesIcon,
    transport: transportIcon,
    transportThin: transportThinIcon,
    tripAdvisor0: tripAdvisorIcon0,
    tripAdvisor1: tripAdvisorIcon1,
    tripAdvisor2: tripAdvisorIcon2,
    tripAdvisor3: tripAdvisorIcon3,
    tripAdvisor4: tripAdvisorIcon4,
    tripAdvisor5: tripAdvisorIcon5,
    tripAdvisor6: tripAdvisorIcon6,
    tripAdvisor7: tripAdvisorIcon7,
    tripAdvisor8: tripAdvisorIcon8,
    tripAdvisor9: tripAdvisorIcon9,
    tripAdvisor10: tripAdvisorIcon10,
    tripAdvisorLogo: tripAdvisorLogoIcon,
    user: userIcon,
    userSolid: userSolidIcon,
    veganFriendly: veganFriendlyIcon,
    wifi: wifiIcon,
    x: xIcon,
};

export default iconFileMapper;

export function getIcon(icon) {
    return iconFileMapper[icon];
}

export const availableIcons = Object.keys(iconFileMapper);
