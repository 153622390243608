import { DiallingCodeOption } from '@/core';

import { FocusEvent } from 'react';
import * as classNames from 'classnames';
import PhoneNumberInput, {
    PhoneNumberValue,
} from '../PhoneNumberInput/PhoneNumberInput';
import styles from './PhoneNumberField.module.scss';

export interface PhoneNumberFieldProps {
    value: PhoneNumberValue;
    label: string;
    error?: string | { code?: string; number?: string };
    name: string;
    handleChange: (event: {
        target: { name: string; value: string | DiallingCodeOption };
    }) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    className?: string;
    isRequired?: boolean;
    subLabel?: string;
    isTouched?: boolean | { code?: boolean; number?: boolean };
    inputRef?: React.RefObject<HTMLInputElement>;
    setValue?: (name: string, value: string) => void;
    sendFormFilledEvent?: (s: string) => void;
}

const PhoneNumberField = ({
    value,
    label,
    error,
    name,
    handleChange,
    onBlur,
    isRequired,
    subLabel,
    isTouched,
    inputRef,
    setValue,
    sendFormFilledEvent,
    className,
    ...props
}: PhoneNumberFieldProps) => {
    const componentIsTouched =
        (typeof isTouched === 'boolean' && isTouched) ||
        (typeof isTouched === 'object' && isTouched.code && isTouched.number);
    let errorString: string | undefined = undefined;
    if (error && typeof error === 'string') {
        errorString = error;
    } else if (
        error &&
        typeof error === 'object' &&
        (error.code || error.number)
    ) {
        errorString = error.code ?? error.number;
    }

    return (
        <div className={classNames(styles.wrapper, className)} {...props}>
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label}
                    {isRequired && <span>*</span>}
                    {subLabel && (
                        <span className={styles.subLabel}>{subLabel}</span>
                    )}
                </label>
            )}

            <PhoneNumberInput
                value={value}
                name={name}
                onChange={handleChange}
                onBlur={onBlur}
                className={styles.inputField}
                setValue={setValue}
                sendFormFilledEvent={sendFormFilledEvent}
            />

            {(componentIsTouched === true ||
                componentIsTouched === undefined) &&
                errorString &&
                errorString.length > 0 && (
                    <div className={styles.error}>{errorString}</div>
                )}
        </div>
    );
};

export default PhoneNumberField;
