import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncRouteLoader from './utils/asyncRouteLoader';
import { TourSearchResultsProvider } from './contexts/TourSearchResultsContext';
import { useFeatureFlag } from './contexts/FeatureFlagContext';
import PageLoading from './Components/PageLoading/PageLoading';
import { Page } from './utils/analytics/enums/EventInteractionEnums';
import { TourPackageProvider } from './contexts/TourPackageContext';

const AsyncAccomOnlyCustomerDetailsPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/AccommodationCustomerDetails/AccommodationCustomerDetails'
        )
);

const AsyncAccomOnlyPayment = asyncRouteLoader(
    () => import('./Scenes/AccommodationPayment/AccommodationPayment')
);

const AsyncAccomOnlyConfirmation = asyncRouteLoader(
    () => import('./Scenes/AccommodationConfirmation/AccommodationConfirmation')
);
const AsyncHomepage = asyncRouteLoader(
    () =>
        import('./Scenes/HomePage/HomePage' /* webpackChunkName: "homepage2" */)
);
const AsyncSearchPageV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/TourSearchResults.v2/TourSearchResults.v2' /* webpackChunkName: "toursearchresults2" */
        )
);
const AsyncTourPageV2 = asyncRouteLoader(
    () => import('./Scenes/Tour.v2/Tour.v2' /* webpackChunkName: "tour2" */)
);
const AsyncAccommodationPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/Accommodation/Accommodation' /* webpackChunkName: "accommodation" */
        )
);
const AsyncAccommodationSearchPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/AccommodationSearchResults/AccommodationSearchResults' /* webpackChunkName: "accommodationsearchresults" */
        )
);
const AsyncFlightHotelsSearchPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/FlightsHotelSearchResults/FlightsHotelSearchResults' /* webpackChunkName: "flighthotelssearchresults" */
        )
);

const AsyncCheckoutRedirect = url =>
    asyncRouteLoader(
        () =>
            import(
                './Components/Redirection/CheckoutRedirect' /* webpackChunkName: "checkoutredirect" */
            ),
        { url }
    );
const AsyncHoldSpacePage = asyncRouteLoader(
    () =>
        import(
            './Scenes/HoldSpace/HoldSpace' /* webpackChunkName: "holdspace" */
        )
);
const AsyncHoldSpaceConfirmationPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/HoldSpaceConfirmation/HoldSpaceConfirmation' /* webpackChunkName: "holdspaceconfirmation" */
        )
);
const AsyncFlightSelectPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/FlightSelect/FlightSelect' /* webpackChunkName: "flightselect" */
        )
);
const AsyncPaymentPage = asyncRouteLoader(
    () => import('./Scenes/Payment/Payment' /* webpackChunkName: "payment" */)
);
const AsyncBookingConfirmationPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/BookingConfirmation/BookingConfirmation' /* webpackChunkName: "bookingconfirmation" */
        )
);
const AsyncDepartureUnavailablePage = asyncRouteLoader(
    () =>
        import(
            './Scenes/DepartureUnavailable/DepartureUnavailable' /* webpackChunkName: "departureunavailable" */
        )
);
const AsyncContactUsPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/ContactUs/ContactUs' /* webpackChunkName: "contactus" */
        )
);
const AsyncFlightContactUsPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/FlightContactUs/FlightContactUs' /* webpackChunkName: "flightcontactus" */
        )
);
const AsyncDestinationsV2Page = asyncRouteLoader(
    () =>
        import(
            './Scenes/Destinations.v2/Destinations.v2' /* webpackChunkName: "destinationsv2" */
        )
);
const AsyncPopularDestinationsV2Page = asyncRouteLoader(
    () =>
        import(
            './Scenes/PopularDestinations.v2/PopularDestinations.v2' /* webpackChunkName: "populardestinationsv2" */
        )
);
const AsyncDestinationV2Page = asyncRouteLoader(
    () =>
        import(
            './Scenes/Destination.v2/Destination.v2' /* webpackChunkName: "destinationv2" */
        )
);
const AsyncDestinationChildV2Page = asyncRouteLoader(
    () =>
        import(
            './Scenes/DestinationChild.v2/DestinationChild.v2' /* webpackChunkName: "destinationchildv2" */
        )
);
const AsyncActivitiesPageV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/Activities.v2/Activities.v2' /* webpackChunkName: "activitiesv2" */
        )
);
const AsyncPopularActivitiesV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/PopularActivities.v2/PopularActivities.v2' /* webpackChunkName: "popularactivitiesv2" */
        )
);
const AsyncActivityPageV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/Activity.v2/Activity.v2' /* webpackChunkName: "activityv2" */
        )
);
const AsyncActivityCategoryPageV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/ActivityChild.v2/ActivityChild.v2' /* webpackChunkName: "activitycategoryv2" */
        )
);
const AsyncBlogHubPage = asyncRouteLoader(
    () => import('./Scenes/BlogHub/BlogHub' /* webpackChunkName: "bloghub" */)
);
const AsyncBlogHubCategoriesPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/BlogHub/BlogHubCategories' /* webpackChunkName: "bloghubcategories" */
        )
);
const AsyncBlogDraftSinglePostPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/BlogDraftSinglePost/BlogDraftSinglePost' /* webpackChunkName: "blogdraft" */
        )
);
const AsyncBlogSinglePostPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/BlogSinglePost/BlogSinglePost' /* webpackChunkName: "blogsingle" */
        )
);
const AsyncProviderTermsPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/ProviderTerms/ProviderTerms' /* webpackChunkName: "providerterms" */
        )
);
const AsyncDealsPageV2 = asyncRouteLoader(
    () => import('./Scenes/Deals.v2/Deals.v2' /* webpackChunkName: "dealsv2" */)
);
const AsyncDealPage = asyncRouteLoader(
    () => import('./Scenes/Deal/Deal' /* webpackChunkName: "deal" */)
);
const AsyncAboutUsPage = asyncRouteLoader(
    () => import('./Scenes/AboutUs/AboutUs' /* webpackChunkName: "aboutus" */)
);
const AsyncNotFound = asyncRouteLoader(
    () => import('./Scenes/NotFound/NotFound' /* webpackChunkName: "404" */)
);
const AsyncErrorPage = asyncRouteLoader(
    () => import('./Scenes/Error/Error' /* webpackChunkName: "error" */)
);
const AsyncBasicCosmicPage = (slug, showUsp = false) =>
    asyncRouteLoader(
        () =>
            import(
                './Scenes/BasicCosmicPage/BasicCosmicPage' /* webpackChunkName: "basiccosmicpage" */
            ),
        { slug, showUsp }
    );
const AsyncNoMatch = asyncRouteLoader(
    () => import('./Scenes/NoMatch/NoMatch' /* webpackChunkName: "nomatch" */)
);
const AsyncFavouritesPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/Favourites/Favourites' /* webpackChunkName: "favourites" */
        )
);
const AsyncIconicToursPageV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/IconicTours.v2/IconicTours.v2' /* webpackChunkName: "iconictoursv2" */
        )
);
const AsyncJobsPage = asyncRouteLoader(
    () => import('./Scenes/Jobs/Jobs' /* webpackChunkName: "jobs" */)
);
const AsyncTailorMadePage = asyncRouteLoader(
    () =>
        import(
            './Scenes/TailorMade/TailorMade' /* webpackChunkName: "tailormade" */
        )
);
const AsyncTailorMadeEnquiryPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/TailorMadeEnquiry/TailorMadeEnquiry' /* webpackChunkName: "tailormadeenquiry" */
        )
);
const AsyncFlightsEnquiryPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/FlightsEnquiry/FlightsEnquiry' /* webpackChunkName: "flightsenquiry" */
        )
);
const AsyncTailorMadeEnquiryConfirmationPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/TailorMadeEnquiryConfirmation/TailorMadeEnquiryConfirmation' /* webpackChunkName: "tailormadeconf" */
        )
);
const AsyncFlightsConfirmationPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/FlightsEnquiryConfirmation/FlightsEnquiryConfirmation' /* webpackChunkName: "flightsconf" */
        )
);

const AsyncFAQsPage = asyncRouteLoader(
    () => import('./Scenes/FAQs/FAQs' /* webpackChunkName: "faqs" */)
);

const AsyncFAQPage = asyncRouteLoader(
    () => import('./Scenes/FAQ/FAQ' /* webpackChunkName: "faq" */)
);

const AsyncAroundTheWorldPage = asyncRouteLoader(
    () =>
        import(
            './Scenes/AroundTheWorld/AroundTheWorld' /* webpackChunkName: "aroundtheworld" */
        )
);
const AsyncLoginHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/LoginHandler/LoginHandler'
        ) /* webpackChunkName: "loginhandler" */
);

const AsyncLogoutHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/LogoutHandler/LogoutHandler'
        ) /* webpackChunkName: "logouthandler" */
);

const AsyncMyAccountHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/MyAccount/MyAccount'
        ) /* webpackChunkName: "myaccount" */
);

const AsyncMyAccountBookingsHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/MyAccountBookings/MyAccountBookings'
        ) /* webpackChunkName: "myaccountbookings" */
);

const AsyncMyAccountBookingHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/MyAccountBooking/MyAccountBooking'
        ) /* webpackChunkName: "myaccountbooking" */
);

const AsyncMyAccountHoldSpacesHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/MyAccountHoldSpaces/MyAccountHoldSpaces'
        ) /* webpackChunkName: "myaccountholdspaces" */
);

const AsyncMyAccountFavouritesHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/MyAccountFavourites/MyAccountFavourites'
        ) /* webpackChunkName: "myaccountfavourites" */
);

const AsyncMyAccountWhoIsGoingsHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/MyAccountWhoIsGoings/MyAccountWhoIsGoings'
        ) /* webpackChunkName: "myaccountwhoisgoings" */
);

const AsyncHoldspaceConvertHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/HoldspaceConvert/HoldspaceConvert'
        ) /* webpackChunkName: "holdspaceconvert" */
);

const AsyncHoldspaceBookingConfirmationHandler = asyncRouteLoader(
    () =>
        import(
            './Scenes/HoldSpaceBookingConfirmation/HoldSpaceBookingConfirmation'
        ) /* webpackChunkName: "holdspacebookingconfirmation" */
);

const AsyncLegacyPreDeparture = asyncRouteLoader(
    () =>
        import(
            './Scenes/LegacyPreDeparture/LegacyPreDeparture'
        ) /* webpackChunkName: "legacypredeparture" */
);

const AsyncPreDeparture = asyncRouteLoader(
    () =>
        import(
            './Scenes/PreDeparture/PreDeparture'
        ) /* webpackChunkName: "predeparture" */
);

const AsyncPreDepartureConfirmation = asyncRouteLoader(
    () =>
        import(
            './Scenes/PreDepartureConfirmation/PreDepartureConfirmation'
        ) /* webpackChunkName: "predepartureconfirmation" */
);
const AsyncTourPackage = asyncRouteLoader(
    () =>
        import(
            './Scenes/TourPackage/TourPackage'
        ) /* webpackChunkName: "tourpackage" */
);
const AsyncTourPackageV2 = asyncRouteLoader(
    () =>
        import(
            './Scenes/TourPackage.v2/TourPackage.v2'
        ) /* webpackChunkName: "tourpackagev2" */
);
export const Routes = () => {
    const { isLoading, features } = useFeatureFlag();
    return (
        <Switch>
            <Route exact path="/" component={AsyncHomepage} />
            <Route exact path="/search">
                <TourSearchResultsProvider>
                    <AsyncSearchPageV2 />
                </TourSearchResultsProvider>
            </Route>
            <Route
                exact
                path="/tour/:tourName/:tourId"
                component={AsyncTourPageV2}
            />
            <Route
                exact
                path="/checkout/flights/:tourId/:tourAvailabilityId"
                component={AsyncFlightSelectPage}
            />
            <Route
                exact
                path="/checkout/payment/:tourId/:tourAvailabilityId"
                component={AsyncPaymentPage}
            />
            <Route
                exact
                path="/checkout/confirmation/:tourId/:tourAvailabilityId"
                component={AsyncBookingConfirmationPage}
            />
            <Route
                exact
                path="/checkout/:tourId/:tourStartDate/:tourEndDate"
                component={AsyncCheckoutRedirect('/checkout/traveller-details')}
            />
            <Route
                exact
                path="/checkout/accom-only/traveller-details"
                component={AsyncAccomOnlyCustomerDetailsPage}
            />
            <Route
                exact
                path="/checkout/accom-only/payment"
                component={AsyncAccomOnlyPayment}
            />
            <Route
                exact
                path="/checkout/accom-only/confirmation"
                component={AsyncAccomOnlyConfirmation}
            />
            <Route
                exact
                path="/holdspace/:tourId/:tourAvailabilityId"
                component={AsyncHoldSpacePage}
            />
            <Route
                exact
                path="/holdspace/convert/confirmation/:bookingReference/:source"
                component={AsyncHoldspaceBookingConfirmationHandler}
            />
            <Route
                exact
                path="/holdspace/confirmation/:tourId/:tourAvailabilityId"
                component={AsyncHoldSpaceConfirmationPage}
            />
            <Route
                exact
                path="/holdspace-checkout/:tourId/:tourStartDate/:tourEndDate"
                component={AsyncCheckoutRedirect('/holdspace')}
            />
            <Route
                exact
                path="/unavailable/:tourId"
                component={AsyncDepartureUnavailablePage}
            />
            <Route
                exact
                path="/accommodation/:countryIsoCode2/:slug"
                component={AsyncAccommodationPage}
            />
            <Route
                exact
                path="/accommodation/search"
                component={AsyncAccommodationSearchPage}
            />
            <Route
                exact
                path="/packages/search"
                component={AsyncFlightHotelsSearchPage}
            />
            <Route exact path="/about-us" component={AsyncAboutUsPage} />
            <Route
                exact
                path="/about-us-faqs"
                component={AsyncBasicCosmicPage('about-us-faqs')}
            />
            <Route
                exact
                path="/atol"
                component={AsyncBasicCosmicPage('atol')}
            />
            <Route
                exact
                path="/atol-faqs"
                component={AsyncBasicCosmicPage('atol-faqs')}
            />
            <Route
                exact
                path="/flights"
                component={AsyncBasicCosmicPage('flights')}
            />
            <Route
                path="/contact-us/flights"
                component={AsyncFlightContactUsPage}
            />
            <Route path="/contact-us" component={AsyncContactUsPage} />
            <Route
                exact
                path="/best-price-promise"
                component={AsyncBasicCosmicPage('best-price-promise')}
            />
            <Route
                exact
                path="/book-with-confidence"
                component={AsyncBasicCosmicPage('book-with-confidence', true)}
            />
            <Route
                exact
                path="/cookies"
                component={AsyncBasicCosmicPage('cookies')}
            />
            <Route
                exact
                path="/financial-protection"
                component={AsyncBasicCosmicPage('financial-protection')}
            />
            <Route
                exact
                path="/agency-terms-and-conditions"
                component={AsyncBasicCosmicPage('agency-terms-and-conditions')}
            />
            <Route
                exact
                path="/giving-back"
                component={AsyncBasicCosmicPage('giving-back')}
            />
            <Route
                exact
                path="/privacy-policy"
                component={AsyncBasicCosmicPage('privacy-policy')}
            />
            <Route
                exact
                path="/terms-of-use"
                component={AsyncBasicCosmicPage('terms-of-use')}
            />
            <Route
                exact
                path="/payment-options"
                component={AsyncBasicCosmicPage('payment-options')}
            />
            <Route
                exact
                path="/data-deletion"
                component={AsyncBasicCosmicPage('data-deletion')}
            />
            <Route
                exact
                path="/destinations"
                component={AsyncDestinationsV2Page}
            />
            <Route
                exact
                path="/destinations/popular"
                component={AsyncPopularDestinationsV2Page}
            />
            <Route
                exact
                path="/destinations/:destinationSlug"
                component={AsyncDestinationV2Page}
            />
            <Route
                exact
                path="/destinations/:destinationSlug/:destinationCategorySlug"
                component={AsyncDestinationChildV2Page}
            />
            <Route exact path="/activities" component={AsyncActivitiesPageV2} />
            <Route
                exact
                path="/activities/popular"
                component={AsyncPopularActivitiesV2}
            />
            <Route
                exact
                path="/activities/:activitySlug"
                component={AsyncActivityPageV2}
            />
            <Route exact path="/tailor-made" component={AsyncTailorMadePage} />
            <Route
                path="/tailor-made/enquiry/:stage?"
                component={AsyncTailorMadeEnquiryPage}
            />
            <Route
                path="/flights/enquiry/:stage?"
                component={AsyncFlightsEnquiryPage}
            />
            <Route
                exact
                path="/pre-departure/:stage?"
                component={AsyncLegacyPreDeparture}
            />
            <Route
                path="/tailor-made/confirmation"
                component={AsyncTailorMadeEnquiryConfirmationPage}
            />
            <Route
                path="/flights/confirmation"
                component={AsyncFlightsConfirmationPage}
            />
            <Route
                exact
                path="/activities/:activitySlug/:activityCategorySlug"
                component={AsyncActivityCategoryPageV2}
            />
            <Route
                exact
                path="/travel-updates"
                component={AsyncBasicCosmicPage('travel-updates')}
            />
            <Route
                exact
                path="/around-the-world"
                component={AsyncAroundTheWorldPage}
            />
            <Route exact path="/blog" component={AsyncBlogHubPage} />
            <Route
                exact
                path="/blog/category/:categoryName/:subCategoryName?/:categoryId"
                component={AsyncBlogHubCategoriesPage}
            />
            <Route
                exact
                path="/blog/draft/:postId"
                component={AsyncBlogDraftSinglePostPage}
            />
            <Route
                exact
                path="/blog/:postTitle/:postId"
                component={AsyncBlogSinglePostPage}
            />
            <Route
                exact
                path="/terms/:termsProviderName"
                component={AsyncProviderTermsPage}
            />
            <Route exact path="/deals/black-friday">
                <AsyncDealsPageV2
                    slug="black-friday"
                    url="/deals/black-friday"
                    page={Page.blackFriday}
                />
            </Route>
            <Route exact path="/deals/january-sale">
                <AsyncDealsPageV2
                    slug="january-sale"
                    url="/deals/january-sale"
                    page={Page.januarySale}
                />
            </Route>
            <Route exact path="/deals">
                <AsyncDealsPageV2
                    slug="deals"
                    url="/deals"
                    page={Page.allDeals}
                />
            </Route>
            <Route exact path="/deals/:dealSlug" component={AsyncDealPage} />
            <Route
                exact
                path="/abta-member"
                component={AsyncBasicCosmicPage('abta-member')}
            />
            <Route exact path="/favourites" component={AsyncFavouritesPage} />
            <Route exact path="/favorites" component={AsyncFavouritesPage} />
            <Route exact path="/faqs" component={AsyncFAQsPage} />
            <Route exact path="/faqs/:faqSlug" component={AsyncFAQPage} />
            <Route
                exact
                path="/iconic-tours"
                component={AsyncIconicToursPageV2}
            />
            <Route
                exact
                path="/invest-in-us"
                component={AsyncBasicCosmicPage('invest-in-us')}
            />
            <Route exact path="/jobs" component={AsyncJobsPage} />
            <Route exact path="/login/handler" component={AsyncLoginHandler} />
            <Route
                exact
                path="/logout/handler"
                component={AsyncLogoutHandler}
            />
            <Route exact path="/myaccount" component={AsyncMyAccountHandler} />
            <Route
                exact
                path="/myaccount/bookings"
                component={AsyncMyAccountBookingsHandler}
            />
            <Route
                exact
                path="/myaccount/bookings/:bookingRef"
                component={AsyncMyAccountBookingHandler}
            />
            <Route
                exact
                path="/myaccount/holdspaces"
                component={AsyncMyAccountHoldSpacesHandler}
            />
            <Route
                exact
                path="/myaccount/favourites"
                component={AsyncMyAccountFavouritesHandler}
            />
            <Route
                exact
                path="/myaccount/pre-departure/confirmation"
                component={AsyncPreDepartureConfirmation}
            />
            <Route
                exact
                path="/myaccount/pre-departure/:stage?"
                component={AsyncPreDeparture}
            />
            <Route
                exact
                path="/myaccount/whoisgoings"
                component={AsyncMyAccountWhoIsGoingsHandler}
            />
            <Route
                exact
                path="/holdspace/convert"
                component={AsyncHoldspaceConvertHandler}
            />
            <Route exact path="/tour/packages">
                {isLoading ? (
                    <PageLoading />
                ) : features?.tourPackagePageVersion === 2 ? (
                    <TourPackageProvider>
                        <AsyncTourPackageV2 />
                    </TourPackageProvider>
                ) : (
                    <AsyncTourPackage />
                )}
            </Route>
            <Route path="/sitemap.xml" />
            <Route path="/robots.txt" />
            <Route exact path="/error" component={AsyncErrorPage} />
            <Route exact path="/404" component={AsyncNotFound} />
            <Route component={AsyncNoMatch} />
        </Switch>
    );
};
