import { ApiResponse } from '@/apis';
import { BasicApiResponse } from '@/endurance';
import { tapApiBaseUrl, tapApiAuthKey } from '../utils/config';

export function get<TResponse>(url: string): Promise<ApiResponse<TResponse>> {
    return fetch(`${tapApiBaseUrl}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
    })
        .then(
            response => response.json() as Promise<BasicApiResponse<TResponse>>
        )
        .then(response => ({
            isSuccess: response.success,
            errors: response.errors ?? [],
            result: response.result,
        }));
}

export function getResponse<TResponse>(
    url: string,
    accessToken?: string
): Promise<TResponse> {
    return fetch(`${tapApiBaseUrl}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken ?? '',
        },
    }).then(response => response.json() as Promise<TResponse>);
}

export function post<TBody, TResponse>(
    url: string,
    body: TBody,
    info?: RequestInit
): Promise<ApiResponse<TResponse>> {
    return fetch(`${tapApiBaseUrl}${url}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
        body: JSON.stringify(body),
        ...(info ?? {}),
    })
        .then(
            response => response.json() as Promise<BasicApiResponse<TResponse>>
        )
        .then(response => ({
            isSuccess: response.success,
            errors: response.errors ?? [],
            result: response.result,
        }));
}

export function postResponse<TBody, TResponse>(
    url: string,
    body: TBody,
    info?: RequestInit
): Promise<TResponse> {
    return fetch(`${tapApiBaseUrl}${url}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
        body: JSON.stringify(body),
        ...(info ?? {}),
    }).then(response => response.json() as Promise<TResponse>);
}
