import { DiallingCodeOption } from '@/core';

import { FocusEvent } from 'react';
import * as classNames from 'classnames';
import DiallingCodeSelect from '../Select/DiallingCodeSelect/DiallingCodeSelect';
import TextInput from '../TextInput/TextInput';
import styles from './PhoneNumberInput.module.scss';

export interface PhoneNumberValue {
    code: DiallingCodeOption | null;
    number: string;
}

export interface PhoneNumberInputProps {
    value: PhoneNumberValue;
    name: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    onChange: (
        event:
            | string
            | {
                  target: {
                      name: string;
                      value: string | DiallingCodeOption | null;
                  };
              }
    ) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    setValue?: (name: string, value: string) => void;
    sendFormFilledEvent?: (s: string) => void;
    className?: string;
}

const PhoneNumberInput = ({
    value,
    name,
    inputRef,
    onChange,
    onBlur,
    className,
    setValue,
    sendFormFilledEvent,
}: PhoneNumberInputProps) => {
    return (
        <div className={classNames(styles.phoneNumberInput, className)}>
            <DiallingCodeSelect
                name={`${name}.code`}
                value={value.code}
                onChange={onChange}
                className={styles.diallingCode}
            />
            <TextInput
                type="tel"
                name={`${name}.number`}
                value={value.number}
                onChange={
                    !sendFormFilledEvent
                        ? onChange
                        : v => {
                              if (setValue) {
                                  setValue(`${name}.number`, v.target.value);
                              } else {
                                  onChange(v);
                              }
                              if (!value.number) {
                                  sendFormFilledEvent('PhoneNumber');
                              }
                          }
                }
                onBlur={onBlur}
                className={styles.phoneNumber}
                placeholder={'Mobile number...'}
                autoComplete="tel-national"
                inputRef={inputRef}
            />
        </div>
    );
};

export default PhoneNumberInput;
